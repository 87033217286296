html {
  width: 100%;
  margin: 0;
}

body {
  width: 100%;
  height: 100vh;
  margin: 0;
  font-size: 20px;
  font-family: Verdana, sans-serif;
  background: url('unicorn.gif') #ddd;
  background-size: 400px;
}

@media (max-width: 756px) {
    body {
      background-size: cover;
      background-position: center;
    }

    h3 {
      font-size: 30px;
    }
}
 h1 {
   font-family: Verdana, sans-serif;
   font-size: 50px;
   color: #11376f;
   text-align: center;
 }

 h4 {
   font-family: Verdana, sans-serif;
   font-size: 25px;
   text-decoration: underline;
   color: #11376f;
   text-align: center;
 }

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 400px;
  max-width: 500px;
  margin: auto;
  margin-top: 100px;
  padding: 30px;
  border: 1px solid #888;
  border-radius: 15px;
  box-shadow: 2px 2px 2px #666;
  background: rgba(255, 255, 255, 0.3);
  text-align: center;
}

@media (max-width: 756px) {
  .container {
    width: 100%;
    padding: 25px 0;
    border-radius: 0;
    box-shadow: inherit;
  }
  input {
    font-size: 20px;
  }
}

.number {
  margin-bottom: 20px;
}

.number {
  background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
  height: 23px;
  border: 0;
  color: #fff;
  margin: 15px;
  padding: 3px 8px;
  text-align: center;
}

@media (max-width: 756px) {
  .number {
    width: 75%;
    height: 50px;
    margin: 10px;
  }
}

.operations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.button {
  flex-basis: 35%;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 2px;
  background: rgba(159, 65, 193, 1);
  color: #efdecd;
  padding: 10px 20px;
  border-radius: 10px;
  border: 0;
  margin: 15px 0;
  box-shadow: 3px 3px 3px rgba(159, 65, 193, 0.5);
}

.button:hover {
  background: rgba(159, 65, 193, 0.8);
}


.result {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  letter-spacing: 2px;
}

#result {
  display: inline-block;
  margin-right: 5px;
}

#solution, #date-field, #time-field, #password {
  color: #11376f;
  text-decoration: none;
  display: inline-block;
  padding: 4px 9px;
  min-width: 40px;
  min-height: 25px;
  background: #ffce9ccc;
}

.error {
  color: #ff0000!important;
}
